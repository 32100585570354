<template>
  <div
    role="button"
    :class="[
      {
        'border-main-125': !open,
        'border-main-pink bg-main-125': open,
        '!cursor-default opacity-80': loading,
      },
      wrapClass,
    ]"
    class="flex flex-grow cursor-pointer select-none items-center justify-between gap-3 rounded-lg border px-4"
  >
    <div class="flex w-full justify-between">
      <slot name="selected" />
      <div class="flex items-center gap-2">
        <span v-if="loading" class="loader size-5" />
        <v-icon class="!text-xl" :class="{ 'rotate-180': open }">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>
    <v-menu v-model="open" :close-on-content-click="false" activator="parent">
      <div
        :class="contentClass"
        class="dropdown-wallet mt-1 overflow-hidden rounded-lg bg-main-150 shadow-lg"
      >
        <div v-if="!disableSearch" class="my-2 px-3">
          <v-text-field
            variant="outlined"
            bg-color="transparent"
            v-model="search"
            :placeholder="$t('common.placeholder.currency')"
          >
            <span
              class="pointer-events-none absolute left-6 flex items-center justify-center"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.7495 15.75L12.487 12.4875M14.2495 8.25C14.2495 11.5637 11.5632 14.25 8.24951 14.25C4.9358 14.25 2.24951 11.5637 2.24951 8.25C2.24951 4.93629 4.9358 2.25 8.24951 2.25C11.5632 2.25 14.2495 4.93629 14.2495 8.25Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </v-text-field>
        </div>
        <div
          v-if="multipleSelect"
          @click="emit('clearFilter')"
          class="mx-3 my-2 flex cursor-pointer items-center justify-center !rounded-lg border !border-main-125 py-[10px] text-sm text-white hover:bg-main-125"
        >
          {{ $t("common.message.clear_filter") }}
        </div>
        <Simplebar
          v-if="listData?.length"
          class="scrollbar-pink flex max-h-[240px] flex-col"
          data-simplebar-auto-hide="false"
          :class="listDataClass"
        >
          <slot name="list-dropdown-item" />
        </Simplebar>
        <div
          v-else
          class="px-3 py-4 text-center text-sm font-medium text-white"
        >
          {{ noDataMessage }}
        </div>
        <slot name="footer" />
      </div>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import Simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";

const emit = defineEmits(["clearFilter"]);

type Props = {
  listData?: any[];
  disableSearch?: boolean;
  noDataMessage?: string;
  wrapClass?: string;
  contentClass?: string;
  multipleSelect?: boolean;
  loading?: boolean;
  listDataClass?: string;
};
defineProps<Props>();

const open = defineModel<boolean>({ default: false });
const search = defineModel<string>("search");
</script>
